import  React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'
import Head from '../components/head'
import GodsGame from './GreekGodsGame/App'


// markup
const GreekGodsGame = () => {
  return (
   <Layout >
      <Head title='Greek Gods memory game' description='Play a memory game to finally learn the most important Greek and Roman gods.'/>
     <h1>Greek Gods and Goddesses Memory Game</h1>
    
   
<p>
Knowing Greek gods and goddesses (as well as their Roman counterparts) is absolutely essential for understanding ancient literature and art. 
This simple game helps you learn the twelve gods of the Greco-Roman pantheon: Zeus, Hera,
Apollo, Athena, Ares, Gephaestos, Hestia, Artemis, Hermes, Demeter, Poseidon and Aphrodite. 
Each god was assigned specific realms and functions. You will need to match these functions to the Greek and Roman names of the deities. 
</p>
<p>
     <GodsGame/>
     </p>
       </Layout>
  )
}

export default GreekGodsGame
